<template>
  <div>
    <b-breadcrumb :items="breadcrumb" class="my-3"></b-breadcrumb>

    <b-nav tabs class="mb-3">
      <b-nav-item active>Information</b-nav-item>
    </b-nav>


    <b-card>
      <div class="row mt-2">
        <div class="col">
          <div class="small text-muted">Name:</div>
          <div>{{ account ? account.name : '' }}</div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <div class="small text-muted">Username:</div>
          <div>{{ account ? account.userName : '' }}</div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col">
          <div class="small text-muted">Roles:</div>
          <div>
            <ul>
              <li v-for="role in roles" :key="role">{{ role }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row mt-3 mb-3">
        <div class="col">
          <div class="small text-muted">Print Template:</div>
          <div class="mt-1">
            <b-form-select v-model="printtemplate" :options="templatelist" v-on:change="onChangeTemplate">
            </b-form-select>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { auth, getRoles } from "@/helpers/auth";
import { printService } from '@/services';

export default {
  data () {
    return {
      breadcrumb: [
        {
          text: 'Home',
          to: '/'
        },
        {
          text: 'Profile',
          active: true
        }
      ],
      account: null,
      roles: [],
      loadlist: false,
      templatelist: [],
      printtemplates: [],
      printtemplate: null
    }
  },
  watch: {
    async loadlist() {
      if(this.loadlist) {
        this.templatelist = [{value: '', text: 'Ask every time'}]
        await this.printtemplates.forEach(item => this.templatelist.push({value: item.id, text: item.name}));
        this.loadlist = false
      }
    }
  },
  methods: {
    async onChangeTemplate(val) {
      if(val == '') {
        localStorage.setItem('printtemplate', '')
        this.$bvToast.toast(`Removing my default print template`, {
          title: 'Success',
          autoHideDelay: 4000,
          variant: 'success'
        })
      } else {
        localStorage.setItem('printtemplate', val)
        this.$bvToast.toast(`Setting default print template`, {
          title: 'Success',
          autoHideDelay: 4000,
          variant: 'success'
        })
      }

    }
  },
  async mounted() {
    this.account = await auth.getAccount();
    this.roles = await getRoles();
    this.printtemplate = localStorage.getItem('printtemplate');
    if(this.printtemplate == null || this.printtemplate == '') this.printtemplate = '';
    printService.getAll('',0,0)
      .then(response => {
        this.printtemplates = response.data
        this.loadlist = true
      })
      .catch(error => {
        this.$bvToast.toast(`${error}`, {
          title: 'Error',
          autoHideDelay: 4000,
          variant: 'danger'
        })
      })
  },
};
</script>

<style scoped>
.form-signin {
  width: 100%;
  max-width: 380px;
  background: #e2e2e2;
}
</style>
